<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>对账管理</template>
        <template v-slot:secondMenu>对账详情</template>
      </breadcrumb-nav>
      <div class="titleBox">查看详情</div>
      <div class="">
        <div class="textBox">附件信息</div>
        <div class="accessoryArea">
          <div class="accessoryArea_one">
            <div class="accessoryArea_title" style="margin-bottom: 10px;">贸易企业对账单附件:</div>
            <div style="" v-if="type == 1">
              <el-upload class="upload-demo" drag :action="uploadUrl" :headers="imgUploadHeaders"
                :on-success="handleImgUploadSuccess" :show-file-list="false">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </el-upload>
              <div class="upload_message">支持扩展名：.rar .zip .pdf .jpg.xls.....,大小10M以内</div>
            </div>
            <div style="padding: 10px;box-sizing: border-box;" v-if="tradFile != ''">
              <div class="accessory_list">
                <div class="accessory_list_text">{{ tradFile }}</div>
                <div>
                  <i class="el-icon-delete" v-if="type == 1" style="margin-right: 10px;cursor: pointer;" @click="deleteFile(1)"></i>
                  <i style="cursor: pointer;" class="el-icon-download" @click="downloadFile(tradFileUrl)"></i></div>
              </div>
            </div>
            <div class="upload_message" v-if="tradFile == '' && type == 2">
              贸易企业暂未上传，请等待对账通过后下载附件
            </div>
          </div>
          <div class="accessoryArea_two">
            <div class="accessoryArea_title" style="margin-bottom: 10px;">购液企业对账单附件:</div>
            <div style="" v-if="type == 2 && titleData.reconciliationStatus == 1">
              <el-upload class="upload-demo" drag :action="uploadUrl" :headers="imgUploadHeaders"
                :on-success="handleImgUploadSuccess" :show-file-list="false">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              </el-upload>
              <div class="upload_message">支持扩展名：.rar .zip .pdf .jpg.xls.....,大小10M以内</div>
            </div>
            <div style="padding: 10px;box-sizing: border-box;" v-if="emptionFile != ''">
              <div class="accessory_list">
                <div class="accessory_list_text">{{ emptionFile }}</div>
                <div><i class="el-icon-delete" v-if="type == 2 && titleData.reconciliationStatus == 1"
                    style="margin-right: 10px;cursor: pointer;" @click="deleteFile(2)"></i> <i style="cursor: pointer;"
                    class="el-icon-download" @click="downloadFile(emptionFileUrl)"></i></div>
              </div>
            </div>
            <div class="upload_message" v-if="emptionFile == '' && type == 1">
              购液企业暂未上传，请等待对账通过后下载附件
            </div>
          </div>

        </div>
      </div>
      <div>
        <el-button type="primary" size="mini" v-if="titleData.reconciliationStatus == 1 && type == 2"
          @click="confirm(titleData.reconciliationId)">确认对账</el-button>
        <el-button type="primary" size="mini" v-if="titleData.reconciliationStatus == 1 && type == 1"
          @click="cancel(titleData.reconciliationId)">取消对账</el-button>
        <el-button type="primary" size="mini" @click="PrintRow">打印</el-button>
        <el-button type="primary" size="mini" @click="ArrangePlan()">导出</el-button>
      </div>
      <div id="printContent">
        <div class="textBox">
          <span
            style="marginRight:50px">{{ type == 1 ? '购液企业：' : type == 2 ? '贸易企业：' : '' }}{{ type == 1 ? titleData.emptionName : titleData.tardName }}</span>
          <span style="marginRight:50px">气站余额：{{ titleData.balance }} 元 </span>
          <span style="marginRight:50px">对账计划日期：{{ titleData.startDate }} 至 {{ titleData.endDate }}</span>
          <span>对账单标题：{{ titleData.reconciliationName }}</span>
        </div>
        <div class="numberBox">
          <div class="settlement">
            <div class="settlement_title">结算量合计</div>
            <div class="settlement_number"><span
                style="fontSize: 36px;fontWeight: bold;">{{ titleData.totalWeight }}</span><span
                style="fontSize: 20px;fontWeight: bold;"> 吨</span></div>
          </div>
          <div class="settlement">
            <div class="settlement_title">总车数</div>
            <div class="settlement_number"><span
                style="fontSize: 36px;fontWeight: bold;">{{ titleData.countCar }}</span><span
                style="fontSize: 20px;fontWeight: bold;"> 车</span></div>
          </div>
          <div class="settlement">
            <div class="settlement_title">总计</div>
            <div class="settlement_number"><span
                style="fontSize: 36px;fontWeight: bold;">￥{{ titleData.totalAmount }}</span><span
                style="fontSize: 20px;fontWeight: bold;"></span></div>
          </div>
        </div>

        <!-- table表格区域 -->
        <unapplied-page-list :reconciliation-list="goodsList" @reconciliation-list='updateRoleList' />
      </div>
      <div style="float: right;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNo" :page-sizes="[10, 20, 30, 50]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from "../../../common/BreadcrumbNav";
import UnappliedPageList from './UpappliedPageList';
import { TradeDetailsRequest, DeleteTradeRequest, orderRequest, confirmRequest, accountExcel, accountExcelOne, updateByTard, updateByEmption } from "network/financial";

export default {
  name: "TradeLookDetails",
  components: {
    BreadcrumbNav,
    UnappliedPageList
  },
  data () {
    return {
      radio1: '0',
      type: localStorage.getItem('type'),
      uploadUrl: this.api.imageUrl + "/trade/purchaseorder/uploadImg",
      tradFile: '',
      tradFileUrl: '',
      emptionFile: '',
      emptionFileUrl: '',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: localStorage.getItem('token')
      },
      queryInfo: {
        query: "",
        pageNo: 1,
        pageSize: 10
      },
      formInline: {
        user: '',
        region: ''
      },
      titleData: {},
      goodsList: [],
      total: 0,
    }
  },
  created () {
    this.getGoodsList();
  },
  methods: {

    handleImgUploadSuccess (response) {
      let reg2 = new RegExp(",");
      let imageUrl = response.data.replace(reg2, '')
      let name = imageUrl.substring(imageUrl.indexOf('/', imageUrl.indexOf('/') + 1) + 1)
      if (this.type == 1) {
        updateByTard({
          reconciliationId: this.$route.query.id,
          path: imageUrl
        }).then(res => {
          if (res.data.code !== 200) {
            return this.alertMessage(res.data.message, 'error');
          }
          this.tradFile = name
          this.tradFileUrl = this.api.imageUrl + "/trade/images/" + imageUrl
        })
      } else if (this.type == 2) {
        updateByEmption({
          reconciliationId: this.$route.query.id,
          path: imageUrl
        }).then(res => {
          if (res.data.code !== 200) {
            return this.alertMessage(res.data.message, 'error');
          }
          this.emptionFile = name
          this.emptionFileUrl = this.api.imageUrl + "/trade/images/" + imageUrl
        })
      }
    },
    deleteFile (newType) {
      if (this.type == 1) {
        updateByTard({
          reconciliationId: this.$route.query.id,
          path: ''
        }).then(res => {
          if (res.data.code !== 200) {
            return this.alertMessage(res.data.message, 'error');
          }
          this.tradFile = ''
          this.tradFileUrl = ''
        })
      } else if (this.type == 2) {
        updateByEmption({
          reconciliationId: this.$route.query.id,
          path: ''
        }).then(res => {
          console.log(res);
          if (res.data.code !== 200) {
            return this.alertMessage(res.data.message, 'error');
          }
          this.emptionFile = ''
          this.emptionFileUrl = ''
        })
      }
    },
    downloadFile (url) {
      let filename = this.emptionFile;
      // let url = this.emptionFileUrl
      return fetch(url).then((res) =>
        res.blob().then((blob) => {
          let a = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          this.alertMessage('文件下载成功')
        })
      );
    },
    getGoodsList () {
      this.titleData = this.$route.query.scope
      this.tradFileUrl = this.api.imageUrl + "/trade/images/" + this.$route.query.scope.tardFilePath
      this.tradFile = this.$route.query.scope.tardFilePath ? this.$route.query.scope.tardFilePath.substring(this.$route.query.scope.tardFilePath.indexOf('/', this.$route.query.scope.tardFilePath.indexOf('/') + 1) + 1) : ''
      this.emptionFileUrl = this.api.imageUrl + "/trade/images/" + this.$route.query.scope.emptionFilePath
      this.emptionFile = this.$route.query.scope.emptionFilePath ? this.$route.query.scope.emptionFilePath.substring(this.$route.query.scope.emptionFilePath.indexOf('/', this.$route.query.scope.emptionFilePath.indexOf('/') + 1) + 1) : ''

      if (this.type == 1) {
        TradeDetailsRequest({
          ...this.queryInfo,
          id: this.$route.query.id
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      }
      if (this.type == 2) {
        orderRequest({
          ...this.queryInfo,
          id: this.$route.query.id
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      }
    },

    // 取消对账
    cancel (id) {
      DeleteTradeRequest(id).then(res => {
        let result = res.data;
        if (result.code !== 200) {
          return this.alertMessage('取消失败', 'error');
        }

        this.alertMessage('对账订单已取消', 'success');
        this.$router.replace({ path: '/pages/biz/gas_factory/finance/reconciliation/TradeOrder' })
      });
    },

    // 确认对账
    confirm (id) {
      if (this.emptionFile == '') {
        this.alertMessage('请先上传对账单附件', 'error');
      } else {
        confirmRequest(id).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('确认订单失败', 'error');
          }
          this.alertMessage('订单确认成功', 'success');
          this.$router.replace({ path: '/pages/biz/gas_factory/finance/reconciliation' })
        })
      }
    },

    // 每页显示的数据条数发送变化
    handleSizeChange (newSize) {
      this.queryInfo.pageNo = 1
      this.queryInfo.pageSize = newSize;
      this.getGoodsList();
    },

    // 当前页码发生变化
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage;
      this.getGoodsList();
    },

    // 跳转到添加商品的界面
    goAddPage () {
      this.$router.push('/add');
    },
    // 监听子组件中发出的事件，重新获取角色列表
    updateRoleList () {
      // this.getRolesList();
    },

    ArrangePlan () {
      let newTime = this.titleData.createDate + ',' + this.titleData.endDate
      if (this.type == 1) {
        accountExcelOne({
          reconciliationDate: newTime,
          satementHeading: this.titleData.reconciliationName,
          reconciliationId: this.$route.query.id,
          liquidName: this.titleData.emptionName,
          name: this.titleData.emptionName + '对账单'
        }).then(res => {
          var content = res.headers['content-disposition'];
          var name = content && content.split(';')[1].split('filename=')[1];
          var fileName = decodeURIComponent(name)
          this.downloadCallback(res, fileName);
        })
      } if (this.type == 2) {
        accountExcel({
          reconciliationDate: newTime,
          satementHeading: this.titleData.reconciliationName,
          reconciliationId: this.$route.query.id,
          name: this.titleData.tardName + '对账单'
        }).then(res => {
          var content = res.headers['content-disposition'];
          var name = content && content.split(';')[1].split('filename=')[1];
          var fileName = decodeURIComponent(name)
          this.downloadCallback(res, fileName);
        })
      }
    },

    //生成下载文件
    downloadCallback (res, fileName) {
      const content = res.data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },

    PrintRow (index, row) {
      const subOutputRankPrint = document.getElementById("printContent")
      // 创建一个新的div
      const printDiv = document.createElement('div')
      printDiv.innerHTML = subOutputRankPrint.innerHTML
      printDiv.style.position = 'fixed'
      printDiv.style.left = '0'
      printDiv.style.top = '0'
      printDiv.style.width = '100%'
      printDiv.style.height = '100%'
      printDiv.style.zIndex = '999999'
      printDiv.style.background = '#fff'
      printDiv.style.overflow = 'auto'
      // 插入到网页中去
      document.body.appendChild(printDiv)
      window.print()
      document.body.removeChild(printDiv)
      return false

    },

  }
}
</script>

<style scoped>
.titleBox {
  width: 100%;
  height: 46px;
  font-size: 20px;
  font-weight: normal;
  color: #0a0b1a;

}

.textBox {
  width: 100%;
  height: 66px;
  font-size: 14px;
  color: #0a0b1a;
  font-weight: normal;
  line-height: 66px;

}

.numberBox {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.settlement {
  width: 528px;
  height: 150px;
  background: #f5f7fa;
  border-radius: 4px;
  opacity: 1;
}

.settlement_title {
  margin-left: 20px;
  margin-top: 23px;
  font-size: 20px;
  font-weight: normal;
  color: #0a0b1a;
}

.settlement_number {
  margin-left: 20px;
  margin-top: 24px;
  color: #54555F;

}

.radioBox {
  margin-bottom: 15px;
}

.buttonBox {
  display: flex;
}

.el-button--text {
  color: var(--theme_bg_color);
}

.el-button:focus,
.el-button:hover {
  border: 0px;
  border-color: #fff;
  color: var(--theme_bg_color);
}

.el-button--primary {
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}

.el-button--primary:focus,
.el-button--primary:hover {
  border-color: var(--theme_bg_color);
  color: #fff;
}

.accessoryArea {
  display: flex;
  margin-bottom: 20px;
}

.accessoryArea_one {
  border: 1px solid #aaaaad;
  width: 400px;
  height: 240px;
  padding: 15px 10px 15px 10px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
}

.accessoryArea_two {
  border-top: 1px solid #aaaaad;
  border-right: 1px solid #aaaaad;
  border-bottom: 1px solid #aaaaad;
  width: 400px;
  height: 240px;
  padding: 15px 10px 15px 10px;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
}

.accessoryArea_title {
  color: #333333;
  font-size: 14px;
}

.accessory_list {
  display: flex;
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
}

.accessory_list_text {
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #666666;
}

.upload_message {
  font-size: 14px;
  color: #999999;
}</style>
